import { useChain, useSpring, useSpringRef } from '@react-spring/web'

import { ANIMATION_CONFIG } from '../../lib/utils/animation'
import { gapSmall } from '../../styles/variables.module.scss'

/** Makes modal fade-in-out animation pretty. */
export const useModalBounce = (visible = false) => {
    const { overlay, container } = states[visible ? 'in' : 'out']

    const overlayRef = useSpringRef()
    const overlayStyle = useSpring({
        ref: overlayRef,
        backdropFilter: `blur(${overlay.backgroundBlurAmount})`,
        WebkitBackdropFilter: `blur(${overlay.backgroundBlurAmount})`,
    })

    const containerRef = useSpringRef()
    const containerStyle = useSpring({
        ref: containerRef,
        ...container,
        config: ANIMATION_CONFIG.BOUNCE,
    })

    useChain([containerRef, overlayRef], [0, 0.2]) // 200ms delay for second element

    return {
        overlay: overlayStyle,
        container: containerStyle,
    }
}

const states = {
    in: {
        overlay: {
            backgroundBlurAmount: gapSmall,
        },
        container: {
            opacity: 1,
            marginBottom: 0,
        },
    },
    out: {
        overlay: {
            backgroundBlurAmount: '0px',
        },
        container: {
            opacity: 0,
            marginBottom: -100,
        },
    },
} as const
